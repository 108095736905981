<template>
    <div>
        <h2 class="teal darken-2 white--text  py-1 text-xs-center">Billing For Order: {{requested_bill.order_id}}</h2>
        <v-divider></v-divider>
        <v-card class="teal lighten-4" style="" >
            <v-card-text class="pa-2" style="font-size: 12px; ">
                <h3 class="dark--text pa-0 ma-0"><b>My Account Balance:</b> <span> {{user_balance}} F CFA</span></h3>
                <h3 class="dark--text pa-0 ma-0"><b>My Credit Points:</b> <span> {{user_credits}} pts</span></h3>
                <p class="dark--text caption pa-0 ma-0 text-xs-center"><span> <b>Note:</b> Buying here is by <b>MTN Mobile Money</b> <b> or Orange Money</b> to <b>Name:</b> <u> "Petty Cash SAS"</u></span></p>
            </v-card-text>
        </v-card>
        <v-card color="white pa-3 " style="border-radius:" >
            <v-layout row>
                <v-flex xs12 md10 offset-md1>
                    <input type="hidden" v-model="payment_ref" ref="payment_ref" id="payment_ref">
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-money-bill fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field
                            class="pa-0 mt-2"
                                id="total_cost" ref="total_cost" v-model="total_cost"
                                label="Total Cost (F CFA)"
                                disabled
                                color="teal"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); amount_help = true; getHelpMessage()" flat fab small outline color="black" class="mt-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-wrench fa-2x"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-select
                            class="pt-0" id="transaction_operator"
                            v-model="transaction_operator" ref="transaction_operator"
                            :items="payment_operators"
                            :rules="[v => !!v || 'Payment Channel is required']"
                            label="Payment Channel"
                            required
                            color="teal"
                            ></v-select>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); operator_help = true; getHelpMessage()" flat fab small outline color="black" class="mt-2" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fa-solid fa-location-dot fa-2x"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-select
                            class="pt-0" id="shipping_town"
                            v-model="selected_shipping_town" ref="shipping_town"
                            :items="shipping_towns"
                            :rules="[v => !!v || 'Your Items Delivery Town is required']"
                            label="Choose Your Town"
                            item-value="id"
                            item-text="name"
                            required @change="getShippingPoints()"
                            color="teal"
                            ></v-select>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); shipping_town_help = true; getHelpMessage()" flat fab small outline color="black" class="mt-2" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fa-solid fa-truck fa-2x"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-select
                            class="pt-0" id="shipping_station"
                            v-model="selected_shipping_location" ref="shipping_station"
                            :items="shipping_collection_points" @change="getDeliveryTime()"
                            item-value="id"
                            item-text="name"
                            :rules="[v => !!v || 'Delivery Location is required']"
                            label="Choose Delivery Location"
                            required
                            color="teal"
                            ></v-select>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); shipping_location_help = true; getHelpMessage()" flat fab small outline color="black" class="mt-2" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-money-bill fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field
                            class="pa-0 mt-2"
                                id="delivery_fee" ref="delivery_fee" v-model="delivery_fee"
                                label="Delivery Fee (F CFA)"
                                disabled
                                color="teal"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); delivery_fee_help = true; getHelpMessage()" flat fab small outline color="black" class="mt-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>

                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fa-solid fa-clock fa-2x "> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field 
                                class="pt-0 mt-0"
                                id="delivery_time" ref="delivery_time" v-model="delivery_time"
                                label="Delivery Time"
                                disabled
                                color="teal"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); delivery_time_help = true; getHelpMessage()" flat fab small outline color="black" class="my-2" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>

            </v-layout>
            <div class=" text-xs-center">
                <v-btn class="white--text" small round color="teal darken-2" @click="confirmPayment()">
                    <span> Submit</span>
                </v-btn>
            </div>
        </v-card>

    </div>
</template>

<script>
    import axios from 'axios'

    export default {

        data() {
            return {
                requested_bill: [],
                payment_operators: [],
                shipping_towns: [],
                shipping_collection_points: [],
                shipping_data: [],

                inputFileName: "Image/PDF of Receipt",
                amount_paid: "",
                total_cost: "",
                user_credits: "",
                user_balance: "",
                selected_shipping_town: "",
                delivery_time: "",
                delivery_fee: "",
                selected_shipping_location: "",
                transaction_operator: "",
                payment_ref: "",
                transaction_code: "",

                amount_help: false,
                operator_help: false,
                shipping_town_help: false,
                delivery_fee_help: false,
                shipping_location_help: false,
                delivery_time_help: false,
                transaction_code_help: false,
                upload_file_help: false,
            }
        },

        mounted(){
            // executes these after the page has been mounted
            this.getRequestedBillDetail();
            this.getOperators();
            this.getUserCredits();
            this.getUserAccountBalance();
            this.getShippingTowns();
            document.title = "PettyCash | Confirm Payment"
        },

        methods:{
            async getRequestedBillDetail(){
                console.log("Get requested Detail")
                this.$store.commit('setIsLoading', true)
                
                const requested_order_id = this.$route.params.requested_order_id  // get requested_order_id from url and send request to server
                await axios
                    .get('/api/v1/market_place/get/user/requested/bills/'+requested_order_id+'/')
                    .then(response => {
                        this.requested_bill = response.data  // get the data and fill into requested_order
                        this.payment_ref = response.data.payment_ref
                        this.total_cost = this.requested_bill.total_cost
                        if(response.data.shipping_location.length > 5){
                            var navigation = this.$router
                            navigation.push('/market_place/shopping_bag/items/transactions/')  // redirect back to campaign
                            this.$store.commit('setSnackBarMessage', "Error: This billing request has already been processed. View the request below.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            
            async getOperators(){
                console.log("Get operators")
                this.$store.commit('setIsLoading', true)
                
                await axios
                    .get('/api/v1/market_place/get/payment/operators')
                    .then(response => {
                        this.payment_operators = response.data  // get the data and fill into operators
                        console.log("payment_operators", this.payment_operators)
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async getUserCredits(){
                console.log("Get credits")
                this.$store.commit('setIsLoading', true)
                
                await axios
                    .get('/api/v1/home/get/user/credits')
                    .then(response => {
                        this.user_credits = response.data[0]["credit"]  // get the data and fill into operators
                        console.log("user_credits", this.user_credits)
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async getUserAccountBalance(){
                console.log("Get balance")
                this.$store.commit('setIsLoading', true)
                
                await axios
                    .get('/api/v1/home/get/user/balance')
                    .then(response => {
                        this.user_balance = response.data[0]["saved_amount"]  // get the data and fill into operators
                        console.log("user_balance", this.user_balance)
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async getShippingTowns(){
                console.log("Get shipping towns")
                this.$store.commit('setIsLoading', true)
                
                await axios
                    .get('/api/v1/market_place/get/shipping/towns')
                    .then(response => {
                        this.shipping_towns = response.data  // get the data and fill into shipping towns
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            
            async getShippingPoints(){
                console.log("Get shippin points")
                this.$store.commit('setIsLoading', true)
                
                await axios
                    .get('/api/v1/market_place/get/shipping/points/'+this.selected_shipping_town)
                    .then(response => {
                        this.shipping_collection_points = response.data
                        
                        this.shipping_data = response.data
                        console.log("shipping_collection_points", this.shipping_collection_points)
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            
            async getDeliveryTime(){
                console.log("Get delivery time")
                for(var i = 0; i < this.shipping_data.length; i++){
                    if (this.shipping_data[i]["id"] === this.selected_shipping_location){
                        this.delivery_fee = this.shipping_data[i]["fee"]+" F CFA" 
                        this.delivery_time = this.shipping_data[i]["time_interval_days"]  + " days"
                    }
                }
            },

            async confirmPayment(){
                if(this.transaction_operator.length == 0){
                    this.$store.commit('setSnackBarMessage', "Please select the payment channel you used")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.transaction_operator.focus()  // this causes a an auto fucos to the element
                }
                else if(this.selected_shipping_town.length == 0){
                    this.$store.commit('setSnackBarMessage', "Please select the town to which the items will be delivered to.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.selected_shipping_town.focus()  // this causes a an auto fucos to the element
                }
                else if(this.selected_shipping_location.length == 0){
                    this.$store.commit('setSnackBarMessage', "Please select the location/point where you will collect the items.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.shipping_station.focus()  // this causes a an auto fucos to the element
                }
                else {
                    this.$store.commit('setIsLoading', true)
                    let formData = new FormData()
                    formData.append('transaction_operator', this.transaction_operator)
                    formData.append('selected_shipping_town', this.selected_shipping_town)
                    formData.append('selected_shipping_location', this.selected_shipping_location)
    
                    const requested_order_id = this.$route.params.requested_order_id  // get campaign id from url and send request to server
                    await axios
                        .post('/api/v1/market_place/complete/bill/transaction/'+requested_order_id+'/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                        .then(response => {
                            console.log(response.data)
                            this.$store.commit('setSnackBarMessage', response.data[0].text)
                            this.$store.commit('setSnackBarColor', response.data[0].color)
                            this.$store.commit('activateSnackBar', true)

                            var navigation = this.$router
                            var store = this.$store  // timers are noted for this
                            if (response.data[0].valid){
                                navigation.push('/market_place/shopping_bag/items/transactions/'+requested_order_id)  // redirect back to campaign
                            }
                        })
                        .catch(error => {
                            if (error.response){
                                this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }else{
                                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
                    this.$store.commit('setIsLoading', false)
                }
            },

            getHelpMessage(){
                if (this.save_amount_help){
                    this.save_amount_help = false
                    this.$store.commit('setDialogTitle', "Save Amount")
                    this.$store.commit('setDialogMessage', "You are required to input your transaction amount.")
                    this.$store.commit('activateDialog', true)
                }else if (this.save_contact_help){
                    this.save_contact_help = false
                    this.$store.commit('setDialogTitle', "Phone Contact")
                    this.$store.commit('setDialogMessage', "You are required to input the phone number used for the transaction.")
                    this.$store.commit('activateDialog', true)
                }else if (this.save_transaction_code_help){
                    this.save_transaction_code_help = false
                    this.$store.commit('setDialogTitle', "Transaction ID")
                    this.$store.commit('setDialogMessage', "Please input the transaction ID or Code proving your transaction here.")
                    this.$store.commit('activateDialog', true)
                }else if (this.save_upload_file_help){
                    this.save_upload_file_help = false
                    this.$store.commit('setDialogTitle', "Transaction File")
                    this.$store.commit('setDialogMessage', "Upload Image/PDF of Transaction Receipt/SMS. We will also need you to upload the image or screenshot of the message or receipt of your transaction for verification purposes.")
                    this.$store.commit('activateDialog', true)
                }else if (this.operator_help){
                    this.operator_help = false
                    this.$store.commit('setDialogTitle', "Transaction Operator")
                    this.$store.commit('setDialogMessage', "Choose the operator, either MTN, Orange, Express Union, Western Union, Bank transactions etc. If its the Bank transaction, give account number here and account name, codes etc.")
                    this.$store.commit('activateDialog', true)
                }
            },

            fileInput(){
                var File= document.getElementById('file')
                if(File.files.item(0)){
                    var FileName = File.files.item(0).name
                    var FileSize = File.files.item(0).size
                    var FileType = File.files.item(0).type
                    console.log(FileType)
                    FileName = FileName.replace(/.*[\/\\]/, '_')  // https://stackoverflow.com/questions/3437786/get-the-size-of-the-screen-current-web-page-and-browser-window
                    var win = window,
                        doc = document,
                        docElem = doc.documentElement,
                        body = doc.getElementsByTagName('body')[0],
                        screenWidth = win.innerWidth || docElem.clientWidth || body.clientWidth,
                        screenHeight = win.innerHeight|| docElem.clientHeight|| body.clientHeight;
                    
                    if (FileSize >= 5242880){
                        this.$store.commit('setSnackBarMessage', "Your SMS/Receipt File exceeded the size limit of 5Mb. Upload a file less than 5 megabytes")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                        this.$refs.file.focus()  // this causes a an auto fucos to the element
                        this.inputFileName = "Image/PDF of Receipt"
                    }
                    else if(!FileType.includes("pdf") && !FileType.includes("jpeg") && !FileType.includes("jpg") && !FileType.includes("png") && !FileSize.includes("doc")){
                        this.$store.commit('setSnackBarMessage', "Your SMS/Receipt File must of format PDF, Image (.png, .jpeg, .jpg) or a file of format .doc")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                        this.$refs.file.focus()  // this causes a an auto fucos to the element
                        this.inputFileName = "Image/PDF of Receipt"
                    }
                    else{
                        if (FileName.length > 20){
                            if (screenWidth <= 760){
                                FileName = FileName.substring(0, 10) + '...' + FileName.substring(FileName.length-9, FileName.length) 
                            }
                        }
                        this.inputFileName = FileName
                        this.file = this.$refs.file.files[0]
                    }
                }
                else{
                    this.inputFileName = "Image/PDF of Receipt"
                }
            },
        },
    }
</script>

<style scoped>
    .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    .inputfile + label {
        font-size: 14px;
        padding: 5px;
        font-weight: 700;
        color: white;
        background-color: rgb(0, 26, 255);
        display: inline-block;
    }

    .inputfile:focus + label,
    .inputfile + label:hover {
        background-color: rgb(55, 67, 230);
    }
    .inputfile + label {
        cursor: pointer; /* "hand" cursor */
    }
    .inputfile:focus + label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }
    .inputfile + label * {
        pointer-events: none;
    }

</style>

